import {
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { FunctionEmitter, WorkflowState } from 'app/types';

@Component({
  selector: 'side-panel-activity',
  templateUrl: './side-panel-activity.component.html',
  styleUrl: './side-panel-activity.component.scss'
})
export class SidePanelActivityComponent {
  @Input() currentWorkFlowState?: WorkflowState = undefined;
  @Input() locked?: boolean = false;
  @Input() hasRights: boolean = false;
  @Input() loading: boolean = false;
  @Input() hasExportedPdf?: boolean = false;
  @Output() startWorkflowEvent: EventEmitter<FunctionEmitter> = new EventEmitter();
  @Output() approveWorkflowEvent: EventEmitter<FunctionEmitter> = new EventEmitter();
  @Output() rejectWorkflowEvent: EventEmitter<FunctionEmitter> = new EventEmitter();
  @Output() exportPdfEvent: EventEmitter<FunctionEmitter> = new EventEmitter();

  readonly Workflow = WorkflowState;

  readonly workflowStatesMap = {
    [WorkflowState.VALIDATED]: {
      icon: 'pi pi-check',
      value: 'Approved',
      class: 'workflow-approved'
    },
    [WorkflowState.NONE]: {
      icon: '',
      value: 'No workflow started',
      class: ''
    },
    [WorkflowState.WAITING]: {
      icon: 'pi pi-clock',
      value: 'Pending approval',
      class: 'workflow-pending'
    },
    [WorkflowState.REJECTED]: {
      icon: 'pi pi-times',
      value: 'Rejected',
      class: 'workflow-rejected'
    },
  }
}
