@if (loading) {
  <div class="skeleton-activity">
    <p-skeleton height="70px" class="skeleton-activity" styleClass="mb-2" />
  </div>
} @else {
  <div class="activity-container">
    <span class="span-header">Activity</span>
    <div class="activity-status">
      <i class="pi pi-verified"></i>
      <span>Verification - </span>
      @if (currentWorkFlowState) {
        <p-tag
          class="worflow-status"
          [icon]="workflowStatesMap[currentWorkFlowState].icon"
          [value]="workflowStatesMap[currentWorkFlowState].value"
          [class]="workflowStatesMap[currentWorkFlowState].class"
        />
      }
      @else {
      <span>No workflow started</span>
      }
      @if (hasExportedPdf) {
        <div class="pdf-exported" (mousedown)="exportPdfEvent.emit()">
          <i class="pi pi-download"></i>
          <span>PDF</span>
        </div>
      }
    </div>

    @if (!locked && hasRights) {
      <div class="activity-actions">
        @if (
          currentWorkFlowState === Workflow.REJECTED ||
          currentWorkFlowState === null
      ) {
          <p-button
            class="activity-button"
            label="Start verification"
            size="small"
            (click)="startWorkflowEvent.emit()"
          />
        }
        @else if (currentWorkFlowState === Workflow.VALIDATED) {
          <p-button
            class="activity-button"
            label="Renew verification"
            size="small"
            (click)="startWorkflowEvent.emit()"
          />
        }
        @else if (currentWorkFlowState === Workflow.WAITING) {
          <p-button
            class="activity-button"
            label="Reject"
            [outlined]="true"
            size="small"
            (click)="rejectWorkflowEvent.emit()"
          />
          <p-button
            class="activity-button"
            label="Approve"
            size="small"
            (click)="approveWorkflowEvent.emit()"
          />
        }
      </div>
    }
  </div>
}
