import {
  Component,
  Input,
} from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import {
  Label,
  LabelType,
} from 'app/types';

@Component({
  selector: 'side-panel-labels',
  templateUrl: './side-panel-labels.component.html',
  styleUrl: './side-panel-labels.component.scss'
})

export class SidePanelLabelsComponent {
  @Input() labels?: Label[] = [];
  @Input() loading: boolean = false;

  readonly LabelsMap = {
    [LabelType.VERIFIED]: {
      displayable: true,
      icon: 'pi pi-verified',
      isPrimeNgIcon: true,
      value: 'Verified',
      class: 'verified-tag',
      onClick: () => null,
    },
    [LabelType.VERIFIED_HAS_BEEN_EDITED]: {
      displayable: true,
      icon: 'assets/icons/shield-off.svg',
      isPrimeNgIcon: false,
      value: 'Verified (Edited)',
      class: 'lost-verified-tag',
      onClick: () => null,
    },
    [LabelType.VERIFIED_EXPIRE_SOON]: {
      displayable: true,
      icon: 'pi pi-clock',
      isPrimeNgIcon: true,
      value: 'Verified',
      class: 'expiring-verified-tag',
      onClick: () => null,
    },
    [LabelType.APPROVAL_STATUS_PENDING]: {
      displayable: false,
      icon: 'pi pi-clock',
      isPrimeNgIcon: true,
      value: 'Pending',
      class: 'pending-tag',
      onClick: () => null,
    },
    [LabelType.APPROVAL_STATUS_REJECTED]: {
      displayable: false,
      icon: 'pi pi-times',
      isPrimeNgIcon: true,
      value: 'Rejected',
      class: 'rejected-tag',
      onClick: () => null,
    },
    [LabelType.TO_UPDATE_OUTDATED]: {
      displayable: true,
      icon: 'pi pi-sync',
      isPrimeNgIcon: true,
      value: 'Outdated',
      class: 'outdated-tag',
      onClick: () => null,
    },
    [LabelType.FLAGGED]: {
      displayable: true,
      icon: 'pi pi-flag',
      isPrimeNgIcon: true,
      value: 'Flagged',
      class: 'flagged-tag',
      onClick: (event: Event, op: OverlayPanel) => this.onTagClick(event, op)
    }
  };

  onTagClick(event: Event, overlayPanel: OverlayPanel) {
    overlayPanel.toggle(event);
  }

  formatDate(date?: Date): string {
    if (!date) return '';

    const [year, month, day] = date.toString().split('T')[0].split('-');
    return `${day}/${month}/${year}`;
  }
}
