@if (loading) {
  <p-skeleton height="26px" class="skeleton-label" styleClass="mb-2" />
} @else if (labels && labels.length) {
  <div class="labels-container">
    @for (label of labels; track label) {
      @if (LabelsMap.hasOwnProperty(label.type) && LabelsMap[label.type].displayable) {
        <p-tag
          class="tag"
          [class]="LabelsMap[label.type].class"
          (click)="LabelsMap[label.type].onClick($event, op)"
        >
          <div class="template-tag">
            <app-icon
              [value]="LabelsMap[label.type].icon"
              [icon]="LabelsMap[label.type].isPrimeNgIcon"
            />
            <span>{{LabelsMap[label.type].value}}</span>
          </div>
        </p-tag>
        <p-overlayPanel #op class="custom-overlay-panel">
          <div class="title">Flag reason</div>
          <div>
            Lastly flagged
            {{ label?.by?.primary_email }} ({{
              formatDate(label?.by?.created_at)
            }}) because "{{ label?.reason }}"
          </div>
        </p-overlayPanel>
      }
    }
  </div>
}
