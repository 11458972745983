import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { DialogState } from 'app/pages/side-panel/side-panel.types';
import { TagDatamart } from 'app/types';

@Component({
  selector: 'side-panel-tags',
  templateUrl: './side-panel-tags.component.html',
  styleUrl: './side-panel-tags.component.scss'
})
export class SidePanelTagsComponent {
  @Input() language?: string = undefined;
  @Input() loading: boolean = false;
  @Input() confidentiality?: string = undefined;
  @Input() documentType?: string = undefined;
  @Input() tags?: TagDatamart[] = [];
  @Input() locked: boolean = false;
  @Input() hasRights: boolean = false;
  @Output() editPropertiesCallback = new EventEmitter();

  readonly DialogState = DialogState;
}
