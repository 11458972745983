import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { GdrivesService } from 'app/services/gdrives/gdrives.service';
import { ReplaySubject, filter } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import {
  FileIcon,
  FolderContentFilters,
  FolderContentFiltersKey,
  NodeMenuItem,
} from './folder-content.types';
import {
  DEFAULT_GOOGLE_AVATAR_URI,
  GoogleMimeTypes,
  EntityDB,
  BulkResponse,
  PrimeFileNode,
  File,
  FileDatamart,
  Tag,
} from 'app/types';
import { EventManager } from 'app/services/events/events.service';
import {
  isGoogleContainer,
  pushIfNotFound,
  shortMimeType,
} from 'app/utils/utils';
import { MultiSelectChangeEvent } from 'primeng/multiselect';


@Component({
  selector: 'app-folder-content',
  templateUrl: './folder-content.component.html',
  styleUrl: './folder-content.component.scss',
})
export class FolderContentComponent implements OnInit, OnDestroy {
  /** Parsed parent path ['drive', 'folder1', ...] */
  parsedParentPath: string[] = [];

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  /** The root node of the files to display. */
  parentNode?: PrimeFileNode;

  /** Files displayed in the table. */
  displayedFiles: PrimeFileNode[] = [];

  /** Files stored but might not be displayed if filtered out. */
  storedFiles: PrimeFileNode[] = [];

  /** Active filters. */
  filters: FolderContentFilters = {
    contributors: { values: [], selected: [] },
    formats: { values: [], selected: [] },
    labels: { values: [], selected: [] },
    tags: { values: [], selected: [] },
  };

  /** Breadcrumb path. */
  path: Array<MenuItem> = new Array<MenuItem>();
  home: MenuItem = { icon: 'pi pi-home', routerLink: '/todo' };

  /** Files selected in the table. */
  selectedFiles: PrimeFileNode[] = [];

  /** Toggle on the footer */
  displayFooter: boolean = false;

  isDriveScanned: boolean = true;

  readonly default_avatar_uri: string = DEFAULT_GOOGLE_AVATAR_URI;

  constructor(
    private gdriveService: GdrivesService,
    private router: Router,
  ) {}

  setParentId(path: string) {
    const parsedParentPath = path.split('/').filter((item) => item);

    this.parsedParentPath = parsedParentPath;
  }

  ngOnInit() {
    this.setParentId(this.router.url);
    EventManager.on('tableRefreshContent', ((event: CustomEvent) => {
      const datamart = event.detail.datamart.processed as PrimeFileNode[];
      const tags = event.detail.tags as BulkResponse<File, string>;
      this.displayedFiles = this.displayedFiles.map((item: PrimeFileNode) => {
        if (tags) {
          const newTagsItem = tags.processed.find(
            (newItem) => newItem.id === item.id,
          );
          if (newTagsItem?.id) {
            item.tags = Object.assign(
              item.tags ?? {},
              newTagsItem.tags ?? {},
            ) as Tag[];
          }
        }

        if (datamart) {
          const newDataMartItem = datamart.find(
            (newItem) => newItem.id === item.id,
          );

          if (newDataMartItem?.id) {
            item.datamart = Object.assign(
              item.datamart ?? {},
              newDataMartItem.datamart ?? {},
            ) as FileDatamart;
          }
        }

        return item;
      });
    }) as EventListener);

    EventManager.on('refreshDrivesStatus', ((event: CustomEvent) => {
      const drives = event.detail.drives;
      const driveId = event.detail.driveId;
      const driveDetails = drives.find(
        (item: { id: string }) => item.id === driveId,
      );
      this.isDriveScanned = driveDetails.scanned;
    }) as EventListener);

    EventManager.on('path', ((details: CustomEvent) => {
      const { path, db }: { db: EntityDB; path: string[] } = details.detail;

      this.path = path.map((id: string) => {
        const folder = db.get(id);
        return { label: folder?.datamart?.name, node: folder, id: id };
      });

      this.setParentId(path.join('/'));
    }) as EventListener);

    // Add an event listener to listen for changes in the route
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        const currentUrl: string = (event as NavigationEnd).url;

        this.setParentId(currentUrl);
      });

    EventManager.on('tableSelectedFiles', () => {
      this.selectedFiles = this.gdriveService.getTableSelectedFile();
      this.toggleFooterAction();
    });

    // Create an event listener to listen for changes in the selected folder
    EventManager.on('containerSelected', ((event: CustomEvent) => {
      const parentNode: PrimeFileNode = event.detail;

      this.updateLocalTableFromRootNode(parentNode);
    }) as EventListener);

    EventManager.on('clearTableSelection', () => {
      this.displayedFiles = [];
    });

    EventManager.on('sideBarGlobalEntitiesAdded', ((event: CustomEvent) => {
      const { db }: { db: EntityDB; entities: PrimeFileNode[] } = event.detail;
      const rootNode = db.get(
        this.parsedParentPath[this.parsedParentPath.length - 1],
      );

      if (!rootNode) return;

      if (!this.parentNode) return;

      if (rootNode.id !== this.parentNode.id) return;

      this.updateLocalTableFromRootNode(rootNode);
    }) as EventListener);
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  clearFilters() {
    this.filters = {
      contributors: { values: [], selected: [] },
      formats: { values: [], selected: [] },
      labels: { values: [], selected: [] },
      tags: { values: [], selected: [] },
    };

    this.applyFilters();
  }

  populateFilters(file: PrimeFileNode) {
    if (isGoogleContainer(file)) return;

    if (file.labels) {
      for (const label of file.labels) {
        pushIfNotFound(
          this.filters.labels.values,
          label,
          (a) => a.type === label.type,
        );
      }
    }

    if (file.tags) {
      for (const tag of file.tags) {
        pushIfNotFound(
          this.filters.tags.values,
          tag.tag,
          (a) => a?.id === tag.tag?.id,
        );
      }
    }

    if (file.mime_type) {
      const entry: FileIcon = {
        shortMimetype: shortMimeType(file.mime_type),
        mimetype: file.mime_type,
        iconLink: file.icon_link,
      };

      pushIfNotFound(
        this.filters.formats.values,
        entry,
        (a) => a.mimetype === entry.mimetype,
      );
    }

    const contributors = file.datamart?.contributors;

    if (contributors) {
      contributors.forEach((contributor) => {
        pushIfNotFound(
          this.filters.contributors.values,
          contributor,
          (a) => a.email === contributor.email,
        );
      });
    }

    const creators = file.datamart?.created_by;

    if (creators) {
      pushIfNotFound(
        this.filters.contributors.values,
        creators,
        (a) => a.email === creators.email,
      );
    }
  }

  updateLocalTableFromRootNode(rootNode: PrimeFileNode) {
    this.clearFilters();
    this.parentNode = rootNode;

    const newFiles: PrimeFileNode[] = rootNode.children.filter(
      (child) =>
        ![GoogleMimeTypes.folder, GoogleMimeTypes.drive].includes(
          child.mime_type as GoogleMimeTypes,
        ),
    );

    this.storedFiles = newFiles;

    for (const entity of newFiles) {
      this.populateFilters(entity);
    }
    this.applyFilters();
  }

  onScroll(event: any) /* eslint-disable-line */ {
    if (
      event.target &&
      event.target.offsetHeight + event.target.scrollTop >=
        event.target.scrollHeight
    ) {
      EventManager.emit('reachBottom');
    }
  }

  clearFilter(type: FolderContentFiltersKey) {
    this.filters[type].selected = [];
  }

  updateFilter(type: FolderContentFiltersKey, e: MultiSelectChangeEvent) {
    this.filters[type].selected = e.value;
    this.applyFilters();
  }

  applyFilters() {
    this.displayedFiles = this.storedFiles.filter((file) => {
      if (
        this.filters.contributors.selected.length &&
        !this.filters.contributors.selected.find((contributor) => {
          const createdByEmail = file.datamart?.created_by?.email;
          const lastModifiedByEmail = file.last_modified_by?.email;
          const contributorsEmails =
            file.datamart?.contributors?.map(
              (contributor) => contributor.email,
            ) || [];

          return (
            contributor.email === createdByEmail ||
            contributorsEmails.includes(contributor.email) ||
            contributor.email === lastModifiedByEmail
          );
        })
      ) {
        return false;
      }

      if (
        this.filters.formats.selected.length &&
        !this.filters.formats.selected.find(
          (format) => format.mimetype === file.mime_type,
        )
      ) {
        return false;
      }

      if (
        this.filters.labels.selected.length &&
        !this.filters.labels.selected.find((label) =>
          file.labels?.find((l) => l.type === label.type),
        )
      ) {
        return false;
      }

      if (
        this.filters.tags.selected.length &&
        !this.filters.tags.selected.find((tag) =>
          file.tags?.find((t) => t.tag?.id === tag.id),
        )
      ) {
        return false;
      }

      return true;
    });
  }

  breadcrumbNavigate(node: NodeMenuItem) {
    EventManager.emit('clearTableSelection');
    EventManager.emit('pathSelected', node.node);
  }

  toggleFooterAction(): void {
    this.displayFooter = this.selectedFiles.length > 0;
  }
}
