import {
  Heartbeat,
  SidePanel,
  Status,
  DialogState,
  LikeButtonData,
  Sections,
} from './side-panel.types';
import {
  LabelType,
  People,
  File,
  VerifiedVersion,
  Version,
  Rating,
  Tag,
  GoogleRessourceRole,
} from 'app/types';
import { Component, Injector, OnInit } from '@angular/core';
import { FileService } from 'app/services/file/file.service';
import { LikeService } from 'app/services/like/like.service';
import {
  LocalStorageService,
  USER_INFO_KEY,
} from 'app/services/local-storage/local-storage.service';
import { RatingService } from 'app/services/rating/rating.service';
import { TagService } from 'app/services/tag/tag.service';
import { lastValueFrom } from 'rxjs';
import { EventManager } from 'app/services/events/events.service';
import { AuthService } from 'app/services/auth/auth.service';
import { ToastService } from 'app/services/toast/toast.service';
import { VersioningService } from 'app/services/versioning/versioning.service';
import { GdrivesService } from 'app/services/gdrives/gdrives.service';
import { getGoogleDriveFileId } from 'app/utils/utils';
import { DescriptionDialogComponent } from './dialogs/description/description-dialog.component';
import { PropertiesDialogComponent } from './dialogs/properties/properties-dialog.component';
import { RatingDialogsComponent } from './dialogs/rating/rating-dialogs.component';
import { FlagDialogComponent } from './dialogs/flag/flag-dialog.component';
import { VersioningDialogComponent } from './dialogs/versioning/versioning-dialog.component';
import { WorkflowApprovalDialogComponent } from './dialogs/workflow-approval/workflow-approval-dialog.component';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrl: './side-panel.component.scss',
})
export class SidePanelComponent implements OnInit {
  readonly DialogState = DialogState;
  readonly Sections = Sections;
  readonly sidePanelStatus = Status;
  readonly dialogs = {
    [DialogState.DESCRIPTION]: { component: DescriptionDialogComponent, injector: Injector.create({
      providers: [],
    })},
    [DialogState.PROPERTIES]: { component: PropertiesDialogComponent, injector: Injector.create({
      providers: [],
    })},
    [DialogState.RATING]: { component: RatingDialogsComponent, injector: Injector.create({
      providers: [],
    })},
    [DialogState.FLAG]: { component: FlagDialogComponent, injector: Injector.create({
      providers: [],
    })},
    [DialogState.VERSIONING]: { component: VersioningDialogComponent, injector: Injector.create({
      providers: [
        { provide: 'OUTPUTS', useValue: {
          createDraft: (linkedGoogleUrl?: string) => this.createNewVersion(linkedGoogleUrl),
        }},
      ],
    })},
    [DialogState.WORKFLOW]: { component: WorkflowApprovalDialogComponent, injector: Injector.create({
      providers: [
        { provide: 'OUTPUTS', useValue: {
          approveDocument: (confidentiality: string, date: Date) => this.approveWorkflow(confidentiality, date),
        }},
      ],
    })},
    [DialogState.NONE]: { component: null, injector: undefined},
  }

  readonly dialogsHeader = {
    [DialogState.DESCRIPTION]: 'Edit description',
    [DialogState.PROPERTIES]: 'Edit properties',
    [DialogState.RATING]: 'Select your rating',
    [DialogState.FLAG]: 'Why are you flagging this?',
    [DialogState.VERSIONING]: 'Add version',
    [DialogState.WORKFLOW]: 'Last step - Confidentiality & Expiration date',
    [DialogState.NONE]: '',
  }

  sidePanel: SidePanel = this.getDefaultSidePanel();
  docIDFromExtension: string | null = null;
  dialogToDisplay: DialogState = DialogState.NONE;
  isDocumentRatedByMe: boolean = false;
  splitButtonItems: MenuItem[] = [];
  likeButtonData?: LikeButtonData = undefined;
  lockedFileClass: string = '';
  hasRights: boolean = false;

  constructor(
    private ratingService: RatingService,
    private tagService: TagService,
    private likeService: LikeService,
    private fileService: FileService,
    private localStorageService: LocalStorageService,
    private authService: AuthService,
    private toastService: ToastService,
    private versioningService: VersioningService,
    private gdriveService: GdrivesService,
  ) {
    this.getConnectedUser();
  }

  ngOnInit(): void {
    this.sidePanel.status = Status.LOADING;
    // If after 2 seconds the docIDFromExtension is still null, we have no document selected
    setTimeout(() => {
      if (!this.docIDFromExtension) {
        this.sidePanel.status = Status.UNKNOWN;
      }
    }, 2000);

    // Heartbeat answer extension
    EventManager.on('message', (event) => {
      switch (event.data.identifier) {
        case Heartbeat.EXTENSION_HEARTBEAT:
          window.parent.postMessage(
            {
              reason: 'heartbeat',
              identifier: Heartbeat.APP_HEARTBEAT,
              sentAt: Date.now(),
            },
            '*',
          );
          break;
        case Heartbeat.EXTENSION_DOCUMENT:
          this.loadSidePanelData(event.data.docId);
          break;
        default:
          break;
      }
    });
  }

  getConnectedUser() {
    const user = this.localStorageService.getItem<People>(USER_INFO_KEY);

    if (user) this.sidePanel.connectedUser = user as any;
    else {
      this.authService
        .getUser()
        .subscribe((user: any) => {
          this.sidePanel.connectedUser = user.user;
          this.localStorageService.setItem<People>(USER_INFO_KEY, user.user);
        });
    }
  }

  getDefaultSidePanel(): SidePanel {
    return {
      connectedUser: undefined,
      status: Status.LOADING,
      loadingSections: [],
      tags: [],
      file: undefined,
      workflow: undefined,
      verifiedHistory: undefined,
    };
  }

  async loadSidePanelData(documentId: string | null, forceRefresh?: boolean) {
    if (!documentId) {
      this.sidePanel.status = Status.UNKNOWN;
      this.sidePanel.file = undefined;
      return;
    }
    if (
      (documentId !== this.sidePanel.file?.id &&
      documentId !== this.docIDFromExtension) ||
      forceRefresh
    ) {
      this.sidePanel = this.getDefaultSidePanel();
      this.getConnectedUser();
      this.docIDFromExtension = documentId;
      this.getTags();
      this.getFileData(documentId);
      this.getWorkflow(documentId);
      this.getVerifiedHistory(documentId);
    }
  }

  async getTags() {
    // Fetch tags list
    this.tagService
      .get()
      .subscribe({
        next: (tagsData) => {
          this.sidePanel.tags = tagsData?.tags;
          this.dialogs[DialogState.PROPERTIES].injector = Injector.create({
            providers: [
              { provide: 'INPUTS', useValue: { tags: tagsData?.tags } },
              { provide: 'OUTPUTS', useValue: { cancelPropertiesDialog: () => this.toggleDialog(DialogState.NONE) } },
            ],
          })
        },
        error: (e) => {
          this.toastService.error({ summary: 'Could not get tags' });
        },
    });
  }

  async getFileData(documentId?: string) {
    if (!documentId) return;
    //this.sidePanel.status = Status.LOADING;
    this.fileService
      .get(documentId)
      .subscribe({
        next: (response) => {
          const file = response.file;
          let flagged = false;
          this.sidePanel.file = file;
          this.setDescriptionProvider(file?.datamart?.description);
          this.setRatingProvider(file?.ratings);
          this.setFlagProvider();
          this.setPropertiesProvider(file);
          file.labels?.forEach((label) => {
            if (label.type === LabelType.FLAGGED) {
              flagged = true;
            }
          });
          this.lockedFileClass = (file.is_locked) ? 'locked-file' : '';
          this.hasRights =
              !(response?.user_permissions === GoogleRessourceRole.READER ||
                response?.user_permissions === GoogleRessourceRole.COMMENTER);
          this.setSplitItems(flagged);
          this.initLikeButton();
          this.sidePanel.status = Status.FILLED;
          this.unsetFromLoadingSection([
            Sections.TITLE,
            Sections.DESCRIPTION,
            Sections.PROPERTIES,
            Sections.RATINGS,
            Sections.LABELS,
            Sections.LIKE
          ]);
        },
        error: (e: any) => {
          if (
            e?.error?.statusCode === 400 &&
            e?.error?.message === 'The file is part of a MyDrive.'
          ) {
            this.sidePanel.status = Status.MY_DRIVE;
          } else if(e?.error?.statusCode === 404) {
            this.sidePanel.status = Status.FILE_NOT_WATCHED;
          }
          else {
            this.toastService.error({
              summary: 'Could not get file',
            });
            this.sidePanel.status = Status.UNKNOWN;
            this.docIDFromExtension = null;
          }
        },
      });
  }

  async getWorkflow(documentId: string) {
    this.fileService
      .getWorkflow(documentId)
      .subscribe({
        next: ({workflow}) => {
          this.sidePanel.workflow = workflow;
          this.unsetFromLoadingSection([Sections.WORKFLOW]);
        },
        error: (e: any) => {
        },
      });
  }

  unsetFromLoadingSection(sections: Sections[]) {
    this.sidePanel.loadingSections = this.sidePanel.loadingSections.filter((section) => !sections.includes(section));
  }

  // SCREEN STATUS SECTION //
  watchDriveFromFileId() {
    if (!this.docIDFromExtension) return;
    this.gdriveService.watchDriveFromFileId(this.docIDFromExtension).subscribe({
      next: () => {
        this.loadSidePanelData(this.docIDFromExtension, true);
      },
      error: (e) => {
        this.toastService.error({
          summary: 'Could not add Overlayer to the shared drive',
        });
      },
    });
  }
  // DESCRIPTION SECTION //
  setDescriptionProvider(description?: string) {
    this.dialogs[DialogState.DESCRIPTION].injector = Injector.create({
      providers: [
        { provide: 'INPUTS', useValue: { description: description } },
        { provide: 'OUTPUTS', useValue: {
          cancelDescriptionDialog: () => this.toggleDialog(DialogState.NONE),
          saveDescriptionDialog: (description: string) => this.updateDocumentDescription(description),
        } },
      ],
    })
  }

  updateDocumentDescription(description?: string) {
    this.toggleDialog(DialogState.NONE);
    this.fileService
      .updateFile(this.sidePanel.file?.id, {
        description: description,
      })
      .subscribe({
        next: () => {
          this.sidePanel.loadingSections.push(Sections.DESCRIPTION);
          if (this.sidePanel.file?.id) {
            this.getFileData(this.sidePanel.file.id);
          }
        },
        error: (e) => {
          this.toastService.error({
            summary: 'Could not update file description',
          });
        },

      });
  }

  // PROPERTIES SECTION //
  setPropertiesProvider(file: File) {
    this.dialogs[DialogState.PROPERTIES].injector = Injector.create({
      providers: [
        { provide: 'INPUTS', useValue: {
          tags: this.sidePanel.tags,
          fileTags: file.tags?.map((tag) => tag.tag),
          fileConfidentiality: file?.datamart?.confidentiality,
          fileType: file?.datamart?.document_type,
          fileLanguage: file?.datamart?.language,
        }},
        { provide: 'OUTPUTS', useValue: {
          cancelPropertiesDialog: () => this.toggleDialog(DialogState.NONE),
          savePropertiesDialog: (
            tags: Tag[],
            confidentiality: string,
            documentType: string,
            language: string
          ) => this.updateDocumentProperties(tags, confidentiality, documentType, language),
        }},
      ],
    });
  }

  // PROPERTIES SECTION //
  async updateDocumentProperties(tags: Tag[], confidentiality: string, documentType: string, language: string) {
    this.sidePanel.loadingSections.push(Sections.PROPERTIES);
    this.saveDocumentConfidentiality(confidentiality);
    this.saveDocumentLanguageAndDocumentType(language, documentType);
    await this.saveDocumentTags(tags);
    this.toggleDialog(DialogState.NONE);
  }

  saveDocumentConfidentiality(confidentiality: string) {
    if (!confidentiality || !this.sidePanel.file?.id) return;
    this.fileService
      .setConfidentiality(this.sidePanel.file?.id, confidentiality.toLowerCase())
      .subscribe({
        next: () => {
          if (this.sidePanel.file?.id) {
            this.getFileData(this.sidePanel.file.id);
          }
        },
        error: (e) => {
          this.toastService.error({
            summary: 'Could not set confidentiality.',
          });
        }
      });
  }

  saveDocumentLanguageAndDocumentType(language: string, documentType: string) {
    if (!this.sidePanel.file?.id) return;
    if (!language && !documentType) return;

    this.fileService.updateFile(this.sidePanel.file?.id, {
      language: language ?? this.sidePanel.file?.datamart?.language,
      document_type: documentType ?? this.sidePanel.file?.datamart?.document_type,
      description: this.sidePanel.file?.datamart?.description,
    }).subscribe({
      next: () => {
        if (this.sidePanel.file?.id) {
          this.getFileData(this.sidePanel.file.id);
        }
      },
      error: (e) => {
        this.toastService.error({
          summary: 'Could not update file properties.',
        });
      },
    }); 
  }

  async saveDocumentTags(tags: Tag[]) {
    if (!this.sidePanel.file?.id) return;
    /** Update the document tags */
    const tagsIds: number[] = [];
    const tagsPromises: Promise<any>[] = [];

    this.sidePanel.loadingSections.push(Sections.PROPERTIES);

    /** Loop over the tags to create the new ones and add all ids in the array */
    tags.forEach((tag: Tag) => {
      if (tag.id !== -1) {
        tagsIds.push(tag.id);
      } else {
        tagsPromises.push(
          lastValueFrom(this.tagService.create(tag.label)).then(
            (response) => {
              if (response.tag?.id) {
                tagsIds.push(response.tag.id);
              }
            },
          ),
        );
      }
    });
    await Promise.all(tagsPromises);
    this.fileService
      .setTags({ file_id: this.sidePanel.file.id, ids: tagsIds })
      .subscribe({
        next: () => {
          if (this.sidePanel.file?.id) {
            this.getFileData(this.sidePanel.file.id);
            this.getTags();
            //this.loadSidePanelData(this.sidePanel.file.id, true);
          }
        },
        error: (e: any) => {
          this.toastService.error({
            summary: 'Could not set tags',
          });
        },
      });
  }

  // RATING SECTION //
  setRatingProvider(ratings?: Rating[]) {
    let userRating = 0;

    ratings?.forEach((rating) => {
      if (rating.rated_by.primary_email === this.sidePanel.connectedUser?.primary_email) {
        userRating = rating.rating;
        this.isDocumentRatedByMe = true;
      }
    });
    this.dialogs[DialogState.RATING].injector = Injector.create({
      providers: [
        { provide: 'INPUTS', useValue: { rating: userRating } },
        { provide: 'OUTPUTS', useValue: {
          applyRating: (rating: number) => this.updateRating(rating),
        } },
      ],
    });
  }

  updateRating(rating: number) {
    if (!this.sidePanel.file?.id) return;
    this.toggleDialog(DialogState.NONE);
    this.sidePanel.loadingSections.push(Sections.RATINGS);
    this.sidePanel.loadingSections.push(Sections.TITLE);
    this.ratingService
      .rateFile({
        file_id: this.sidePanel.file?.id,
        rating: rating,
      })
      .subscribe({
        next: () => {
          if (this.sidePanel.file?.id) {
            this.getFileData(this.sidePanel.file.id);
          }
        },
        error: () => {
          this.sidePanel.loadingSections = [];
          this.toastService.error({
            summary: 'Could not rate document',
          });
        }
      });
  }

  // WORKFLOW SECTION //
  startWorkflow() {
    if (!this.sidePanel.file?.id) return;
    this.sidePanel.loadingSections.push(Sections.WORKFLOW);
    this.fileService
      .setFileAsVerified(this.sidePanel.file?.id)
      .subscribe({
        next: (response) => {
          if (this.sidePanel.file?.id) {
            this.getWorkflow(this.sidePanel.file.id);
            this.getFileData(this.sidePanel.file.id);
          }
        },
        error: (e) => {
          this.sidePanel.loadingSections = [];
          this.toastService.error({
            summary: 'Could not send file verification',
          });
        },
      });
  }

  approveWorkflow(confidentiality: string, date?: Date) {
    if (!this.sidePanel.file?.id) return;
    this.toggleDialog(DialogState.NONE);
    this.sidePanel.loadingSections.push(Sections.WORKFLOW);
    this.sidePanel.loadingSections.push(Sections.LABELS);
    this.sidePanel.loadingSections.push(Sections.VERSIONING);
    this.fileService
      .setFileAsApproved(
        this.sidePanel.file?.id,
        confidentiality.toLowerCase(),
        (date)
        ? new Date(date).toISOString()
        : null,
      )
      .subscribe({
        next: (response) => {
          if (this.sidePanel.file?.id) {
            this.getWorkflow(this.sidePanel.file.id);
            this.getFileData(this.sidePanel.file.id);
            this.getVerifiedHistory(this.sidePanel.file.id);
          }
        },
        error: (e) => {
          this.sidePanel.loadingSections = [];
          this.toastService.error({
            summary: 'Could not approve file',
          });
        }
      });
  }

  rejectWorkflow() {
    if (!this.sidePanel.file?.id) return;
    this.sidePanel.loadingSections.push(Sections.WORKFLOW);
    this.fileService
      .setFileAsRejected(this.sidePanel.file?.id)
      .subscribe({
        next: (response) => {
          if (this.sidePanel.file?.id) {
            this.getWorkflow(this.sidePanel.file.id);
            this.getFileData(this.sidePanel.file.id);
          }
        },
        error: (e) => {
          this.sidePanel.loadingSections = [];
          this.toastService.error({
            summary: 'Could not reject file',
          });
        },
      });
  }

  exportPdf() {
    if (!this.sidePanel.workflow?.id) return;
    this.fileService.getDownloadPDFURL(this.sidePanel.workflow.id)
    .subscribe({
      next: ({url}) => {
        this.downloadAndOpenPDF(url);
      },
      error: () => {
        this.toastService.error({
          summary: 'Could not download file',
        });
      },
    });
  }

  downloadAndOpenPDF(url: string) {
    const link = document.createElement('a');
    link.href = url;

    if (this.sidePanel.file?.datamart?.name) {
      link.download = `${this.sidePanel.file.datamart.name}.pdf`;
    }
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  // VERSIONING SECTION //
  createNewVersion(linkedGoogleUrl?: string) {
    if (linkedGoogleUrl) {
      this.createDraftFromExistingFile(linkedGoogleUrl);
    } else {
      this.createDraftFromCurrentFile();
    }
  }

  createDraftFromCurrentFile() {
    if (!this.sidePanel.file?.id) return;
    this.toggleDialog(DialogState.NONE);
    this.sidePanel.loadingSections.push(Sections.VERSIONING);
    this.versioningService.createDraft(this.sidePanel.file?.id)
      .subscribe({
        next: () => {
          if (this.sidePanel.file?.id) {
            this.getVerifiedHistory(this.sidePanel.file.id);
          }
      },
      error: () => {
        this.toastService.error({
          summary: 'Could not create a new version',
        });
        this.sidePanel.loadingSections = [];
      },
    });
  }

  createDraftFromExistingFile(linkedGoogleUrl: string) {
    if (!this.sidePanel.file?.id) return;

    const fileId = getGoogleDriveFileId(linkedGoogleUrl);
    if (fileId === null) {
      this.toastService.error({
        summary: 'The given url is not a google drive url',
      });
      return;
    }
    if (this.sidePanel?.verifiedHistory?.history?.id) {
      this.toggleDialog(DialogState.NONE);
      this.sidePanel.loadingSections.push(Sections.VERSIONING);
      this.versioningService
        .attachFileToHistory(this.sidePanel.verifiedHistory.history.id, fileId)
        .subscribe({
          next: () => {
            if (this.sidePanel.file?.id) {
              this.getVerifiedHistory(this.sidePanel.file.id);
            }
          },
          error: (e: any) => {
            this.toastService.error({
              summary: 'Could not link file to a new version',
            });
          },
        });
    } else {
      this.toastService.error({
        summary: 'Could not link file to a new version',
      });
    }
  }

  // LIKE SECTION //
  likeDocument() {
    if (!this.sidePanel.file?.id) return;
    this.sidePanel.loadingSections.push(Sections.LIKE);
    this.likeService
      .like(this.sidePanel.file?.id)
      .subscribe({
        next: () => {
          this.getFileData(this.sidePanel.file?.id);
        },
        error: () => {
          this.sidePanel.loadingSections = [];
          this.toastService.error({
            summary: 'Could not like document',
          });
        }
      });
  }

  dislikeDocument() {
    if (!this.sidePanel.file?.id) return;
    this.sidePanel.loadingSections.push(Sections.LIKE);
    this.likeService
      .unlike(this.sidePanel.file?.id)
      .subscribe({
        next: () => {
          this.getFileData(this.sidePanel.file?.id);
        },
        error: () => {
          this.sidePanel.loadingSections = [];
          this.toastService.error({
            summary: 'Could not remove like on document',
          });
        }
      });
  }

  // FLAG SECTION //
  setFlagProvider() {
    this.dialogs[DialogState.FLAG].injector = Injector.create({
      providers: [
        { provide: 'OUTPUTS', useValue: {
          cancelFlagDialog: () => this.toggleDialog(DialogState.NONE),
          flagDocument: (reason: string) => this.flagDocument(reason),
        }},
      ],
    });
  }

  flagDocument(reason: string) {
    if (!this.sidePanel.file?.id) return;
    this.sidePanel.loadingSections.push(Sections.LABELS);
    this.toggleDialog(DialogState.NONE);
    this.fileService
      .flagDocument(this.sidePanel.file?.id, reason)
      .subscribe({
        next: () => {
          if (this.sidePanel.file?.id) {
            this.getFileData(this.sidePanel.file.id);
          }
        },
        error: (e: any) => {
          this.toastService.error({
            summary: 'Could not flag document',
          });
        },
      });
  }

  unflagDocument() {
    if (!this.sidePanel.file?.id) return;
    this.sidePanel.loadingSections.push(Sections.LABELS);
    this.fileService
      .unflagDocument(this.sidePanel.file?.id)
      .subscribe({
        next: () => {
          if (this.sidePanel.file?.id) {
            this.getFileData(this.sidePanel.file.id);
          }
        },
        error: (e: any) => {
          this.toastService.error({
            summary: 'Could not remove flag from document',
          });
        },
      });
  }

  // LOCKED FILE SECTION //
  unlockFile() {
    if (!this.sidePanel.file?.id) return;
    this.fileService.unlockFile(this.sidePanel.file?.id).subscribe({
      next: () => {
        if (this.sidePanel.file?.id) {
          this.loadSidePanelData(this.sidePanel.file.id, true);
        }
        this.toastService.success({
          summary: 'File has been unlocked',
        });
      },
      error: (e) => {
        if (e.error.statusCode === 409) {
          if (this.sidePanel.file?.id) {
            this.loadSidePanelData(this.sidePanel.file.id, true);
          }
          this.toastService.success({
            summary: 'File has been unlocked',
          });
        } else {
          this.toastService.error({
            summary: 'Could not unlock file',
          });
        }
      },
    });
  }

  // FOOTER BUTTONS SECTION //
  initLikeButton() {
    this.likeButtonData = {
      likedByMe: false,
      likesCount: this.sidePanel.file?.likes?.length ?? 0,
      onClick: () => {
        if (this.sidePanel.file?.likes?.length) {
          this.dislikeDocument();
        } else {
          this.likeDocument();
        }
      },
    }
    
    this.sidePanel.file?.likes?.forEach((like) => {
      if (
        like?.liked_by?.primary_email === this.sidePanel.connectedUser?.primary_email &&
        this.likeButtonData
      ) {
        this.likeButtonData.likedByMe = true;
      }
    });
    
  }

  setSplitItems(flagged: boolean) {
    this.splitButtonItems = [
        {
          label: (flagged) ? 'Unflag content' : 'Flag content',
          command: () => {
            (flagged)
            ? this.unflagDocument()
            : this.toggleDialog(DialogState.FLAG);
          },
        },
        {
          label: 'Edit description',
          command: () => {
            this.toggleDialog(DialogState.DESCRIPTION);
          },
        },
    ];
    if (!this.hasRights) {
      this.splitButtonItems = this.splitButtonItems.slice(0, 1);
    }
  }

  async getVerifiedHistory(documentId: string) {
    this.versioningService.getHistory(documentId).subscribe({
      next: (response: VerifiedVersion) => {
        this.sidePanel.verifiedHistory = response;
        this.sidePanel.verifiedHistory.history.versions = this.orderVersioning(response.history.versions);
        this.unsetFromLoadingSection([Sections.VERSIONING]);
      }
    });
  }

  orderVersioning(versions: Version[]): Version[] {
    return versions.sort((a, b) => {
      if (!a.version) return -1;
      if (!b.version) return 1;
      return b.version - a.version;
    });
  }

  toggleDialog(dialogState: DialogState) {
    this.dialogToDisplay = dialogState;
  }
}
