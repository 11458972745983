import { Component, OnInit, ViewChild } from '@angular/core';
import {
  LocalStorageService,
  USER_INFO_KEY,
} from 'app/services/local-storage/local-storage.service';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { GdrivesService } from 'app/services/gdrives/gdrives.service';
import { lastValueFrom } from 'rxjs';
import {
  People,
  User,
  DEFAULT_GOOGLE_AVATAR_URI,
  Contribution,
  ContributionsResponse,
  File,
} from 'app/types';
import { Router } from '@angular/router';
import { EventManager } from 'app/services/events/events.service';
import { AutoComplete } from 'primeng/autocomplete';

type SearchAll = {
  label: string;
  icon?: string;
  search?: string;
};
@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrl: './top-bar.component.scss',
})
export class TopBarComponent implements OnInit {
  @ViewChild('autocomplete') autocomplete: AutoComplete | undefined;
  avatarUrl: string = '';
  hideByKeyboardInput: boolean = false;
  selectedItem: string | undefined = undefined;
  allPeople: Array<People> = new Array<People>();
  filteredSearch: Array<File | People | SearchAll | Contribution> = new Array<
    File | People | SearchAll | Contribution
  >();

  keySearchBarEvent: string = '';
  readonly default_avatar_uri: string = DEFAULT_GOOGLE_AVATAR_URI;
  readonly maxSearchResults: number = 5;

  constructor(
    private localStorageService: LocalStorageService,
    private gdriveService: GdrivesService,
    private router: Router,
  ) {}

  async ngOnInit() {
    this.avatarUrl =
      this.localStorageService.getItem<User>(USER_INFO_KEY)?.people
        ?.photo_uri ?? this.default_avatar_uri;
  }

  escapeRegExp(text: string): string {
    return text.replace(/[\\"]/g, '\\$&');
  }

  async filterSearch(event: AutoCompleteCompleteEvent) {
    const promises: Promise<ContributionsResponse>[] = [];
    const query: string = event.query;
    let fileArray: Array<File> = new Array<File>();
    let peopleArray: Array<People | Contribution> = new Array<
      People | Contribution
    >();
    const allResults: Array<File | People | Contribution> = new Array<
      File | People | Contribution
    >();

    const nameQuery = query
      .split(' ')
      .map((w) => `name contains "${this.escapeRegExp(w)}"`);

    const GQuery = `(${nameQuery.join(' or ')} or fullText contains '"${this.escapeRegExp(query)}"') and trashed=false and mimeType != 'application/vnd.google-apps.folder'`;
    const peopleFound = lastValueFrom(
      this.gdriveService.getContributions(GQuery),
    );
    peopleFound.then((data) => {
      data?.contributions.forEach((contrib) => {
        if (contrib.people?.email?.includes('@') ) {
          peopleArray.push(contrib);
          allResults.push(contrib);
        }
        
      });
      // Sort peopleArray by contributions
      peopleArray.sort((a, b) => {
        return (b as Contribution).count - (a as Contribution).count;
      });
    });
    promises.push(peopleFound);
    const filesFound = lastValueFrom(
      this.gdriveService.searchFiles({
        search: GQuery,
      }),
    );
    filesFound.then((data) => {
      data.files.forEach((file) => {
        fileArray.push(file);
        allResults.push(file);
      });

      Promise.all(promises)
        .then(() => {
          fileArray = fileArray.slice(0, this.maxSearchResults);

          // Limit peopleArray to maxSearchResults
          peopleArray = peopleArray.slice(0, this.maxSearchResults);
          this.filteredSearch = [...fileArray, ...peopleArray];
          if (this.filteredSearch.length === 0) {
            this.filteredSearch.push({ label: 'No results found.' });
          } else {
            this.filteredSearch.push({
              label: 'Show all results',
              search: this.selectedItem,
            });
          }
          this.gdriveService.setSearchResults(allResults);
          setTimeout(() => {
            EventManager.emit('searchResults');
          }, 300);
          this.keySearchBarEvent = event.query;
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }

  hideOverlay() {
    if (this.hideByKeyboardInput) {
      this.autocomplete?.hide();
      this.hideByKeyboardInput = false;
    }
  }

  displaySearchResult(event: KeyboardEvent) {
    if (
      event.key !== 'Enter' ||
      (event.target as HTMLInputElement).value === ''
    )
      return;
    this.hideByKeyboardInput = true;
    this.displayAllResults(true);
  }

  displayAllResults(keyboard?: boolean) {
    this.router.navigate(['/search']);
    if (!keyboard) {
      /** Space added to keep search input in autocomplete for PrimeNG component */
      this.selectedItem = this.keySearchBarEvent + ' ';
    }
    setTimeout(() => {
      EventManager.emit('searchResults');
    }, 300);
  }

  openFileToSidePanel(file: File) {
    window.open(`${file.webview_link}`, '_blank');
  }

  openGoogleContacts(contribution: Contribution) {
    window.open(`https://contacts.google.com/@${contribution.people.email}`, '_blank');
  }

  getTooltipTags(file: File): string {
    let tags: string = '';

    file?.tags?.forEach((tag) => {
      tags += tag?.tag?.label + ', ';
    });
    return tags.slice(0, -2);
  }

  getRatingAverage(file: File): number {
    if (!file?.ratings?.length) {
      return 0;
    }
    return (
      file.ratings.reduce((sum, value) => sum + value.rating, 0) /
      file.ratings.length
    );
  }
}
