import {
  Component,
  Input,
} from '@angular/core';
import { People } from 'app/types';

@Component({
  selector: 'side-panel-contributors',
  templateUrl: './side-panel-contributors.component.html',
  styleUrl: './side-panel-contributors.component.scss'
})
export class SidePanelContributorsComponent {
  @Input() creator?: People = undefined;
  @Input() contributors?: People[] = [];

  formatEmailToName(email: string | undefined): string {
    if (!email) return '';

    return email.split('@')[0].split('.')
    .map(s => s.charAt(0).toUpperCase() + s.substring(1).toLowerCase()).join(' ');
  }
}
